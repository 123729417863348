import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { FormCardSpaced } from 'modules/common';
import { profileMaxPersonalDonationSelector } from 'core/login';
import WidgetsContainer from 'modules/common/widgets/widgets-container.component';
import { WIDGETS } from 'modules/common/widgets/tools';
import {
  useMultiplierAmounts,
  DEFAULT_PREVIEW_AMT,
} from 'modules/pages/upsells/drawer/new-upsell/sections/amount-multiplier/tool';

function UpsellWidgets({ editedUpsell, onUpdateEditedUpsell, className, widgetOptions, primaryColor, secondaryColor }) {
  const maxPersonalDonation = useSelector(profileMaxPersonalDonationSelector);
  const selectableWidgets = WIDGETS.filter(widget => widgetOptions.includes(widget.value));

  let [donationAmounts] = useMultiplierAmounts({
    previewAmount: DEFAULT_PREVIEW_AMT,
    maxPersonalDonation,
    editedUpsell,
  });
  donationAmounts = donationAmounts.map(amt => ({ amount: amt, id: amt }));

  const addWidgetText = `Choose between ${selectableWidgets
    .map(widget => widget.label)
    .join(', ')
    .replace(/, ([^,]*)$/, ', and $1')}.`;

  return (
    <FormCardSpaced title="Widgets" subtitle="Add Widgets to your upsells to boost conversions." className={className}>
      <WidgetsContainer
        widgets={editedUpsell.widgets || []}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        isPartOfUpsell
        donationAmounts={donationAmounts}
        disabled={editedUpsell._isDisabled}
        selectableWidgets={selectableWidgets}
        onChangeWidgets={onUpdateEditedUpsell}
        addWidgetText={addWidgetText}
        upsellType={editedUpsell.type}
        editedUpsell={editedUpsell}
        isDraggable={false}
        upsellAmounts={donationAmounts}
      />
    </FormCardSpaced>
  );
}

UpsellWidgets.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  className: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  widgetOptions: PropTypes.array.isRequired,
};

UpsellWidgets.defaultProps = {
  primaryColor: '#D0021A',
  secondaryColor: '#023D7B',
};

export default UpsellWidgets;
