import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { profileMaxPersonalDonationSelector } from 'core/login';
import { AddListItemButton, useAmplitude } from 'modules/common';

import WidgetContainer from 'modules/common/widgets/widget-container.component';
import { WIDGETS_MAP, generateNewWidget, getWidgetDefaults } from 'modules/common/widgets/tools';

import 'modules/pages/pages.scss';
import 'modules/common/custom-fields/custom/custom.scss';
import { reorderIndexArray } from '../../storefront/settings/tabs/categories/category.tools';
import { WidgetsContainerDraggable } from './widgets-container-draggable.component';
import { getWidgetId } from './widgetTypes/thermometer/tools';
import { noop } from 'lodash';
import SlateUpsell from '../../pages/upsells/drawer/new-upsell/slate.component';

const WidgetsContainer = ({
  widgets,
  primaryColor,
  selectableWidgets,
  secondaryColor,
  donationAmounts,
  upsellType,
  isPartOfUpsell,
  disabled,
  editedUpsell,
  onChangeWidgets,
  isDraggable,
  isPetitionPage = false,
  isStorefront = false,
  page,
  upsellAmounts,
}) => {
  const confettiWidgetsMaxedOut = useMemo(() => {
    const numberOfConfetti = new Set(
      widgets
        .filter(widget => widget.type === 'CONFETTI' && !widget._destroy)
        .map(widget => widget.confettiTriggerElement)
    ).size;

    return numberOfConfetti > 14;
    }, [widgets])


  const maxPersonalDonation = useSelector(profileMaxPersonalDonationSelector);
  // const [updatedSelectableWidgets, updateSelectableWidgets] = useState(setSelectableWidgets());
  const [logEvent] = useAmplitude();
  const addNewWidget = () => {
    logEvent('click create widget');
    const updatedWidgets = [...widgets, generateNewWidget(widgets)];
    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  const updatedSelectableWidgets = useMemo(() => {
    let newSelectableWidgets = selectableWidgets;
    if (confettiWidgetsMaxedOut || isStorefront || (upsellType && upsellType !== SlateUpsell.type)) {
      newSelectableWidgets = newSelectableWidgets.filter(w => w.value !== 'CONFETTI');
    } else {
      const confettiWidget = { label: 'Confetti Animation', value: 'CONFETTI' };
      if (!newSelectableWidgets.some(w => w.value === confettiWidget.value)) {
        newSelectableWidgets = [...newSelectableWidgets, confettiWidget];
      }
    }
    return newSelectableWidgets;
  }, [selectableWidgets, confettiWidgetsMaxedOut, isStorefront, upsellType]);

  const addWidgetText = useMemo(() => {
    const widgetText = (
      <span>
      Choose between {updatedSelectableWidgets
      .map(widget => widget.label)
      .join(', ')
      .replace(/, ([^,]*)$/, ', and $1')}.
      </span>
    );

    if (confettiWidgetsMaxedOut) {
      return (
        <div>
          {widgetText}
          <div style={{ color: 'red' }}>The limit of 15 confetti widgets per page has been reached.</div>
       </div>
      );
    }

    return widgetText;
  }, [updatedSelectableWidgets, confettiWidgetsMaxedOut]);

  const updateWidget = (widgetId, name, value) => {
    if (name === 'widgets') {
      if (value.type === 'THERMOMETER') {
        return onChangeThermometerWidget(widgetId, value);
      }

      return replaceExisting(widgetId, value);
    }

    const updatedWidgets = widgets.map(widget => {
      if (widget.id !== widgetId) return widget;
      return { ...widget, [name]: value };
    });
    if (name === '_isCardOpen') {
      return onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } }, noop, true);
    }
    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  const replaceExisting = (widgetId, value) => {
    // if we are adding a destroyed widget back then remove the destroyed widget from the page
    let updatedWidgets = widgets.filter(widget => widget.id !== value.id);
    // const replacedWidget = widgets.find(widget => widget.id === value.id);

    // replace the temp widget with the selected one - if adding back we make a whole new widget
    // just get rid of the old one all together (no destory flag)
    updatedWidgets = updatedWidgets.map(widget => {
      const matchingId = widget.id === widgetId;
      if (!matchingId) return widget;
      return {
        ...widget,
        ...value,
        _destroy: false,
        id: widgetId,
      };
    });

    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  /**
   * changing a widget type resets the entire widget object
   */
  const onChangeWidgetType = (widget, inputType) => {
    const widgetId = widget.id;
    const oldWidget = widget;
    const isChangingFromSavedWidgetToThermometer = inputType === 'THERMOMETER' && !oldWidget._isNew;
    const isChangingFromSavedThermometer = oldWidget.type === 'THERMOMETER' && !oldWidget._isNew;
    const bothConditions = isChangingFromSavedThermometer || isChangingFromSavedWidgetToThermometer;
    let updatedWidgets = widgets.map(widget => {
      if (widget.id !== widgetId) return widget;
      return {
        id: isChangingFromSavedWidgetToThermometer ? getWidgetId(widgets) : widget.id,
        type: inputType,
        _isNew: bothConditions ? true : widget._isNew,
        _name: (WIDGETS_MAP[inputType] || {}).label,
        _destroy: widget._destroy,
        _isCardOpen: widget._isCardOpen,
        ...getWidgetDefaults({ inputType, isPetitionPage, secondaryColor, maxPersonalDonation }),
      };
    });
    if (bothConditions) {
      updatedWidgets.push({
        ...oldWidget,
        _destroy: true,
      });
    }
    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  const onChangeThermometerWidget = (widgetId, value) => {
    const updatedWidgets = widgets.map(widget => {
      if (widget.id !== widgetId) return widget;

      return { ...value };
    });

    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  /**
   * remove a widget from a page
   */
  const removeWidget = widgetId => {
    const updatedWidgets = widgets
      .map(widget => {
        if (widget.id !== widgetId) return widget;

        // if the one we want to remove - if existed on page already then set flag to hide
        // so we can set destroy flag on api call - else just discard
        if (!widget._isNew) {
          widget._destroy = true;
          return widget;
        }

        return null;
      })
      .filter(v => Boolean(v));

    onChangeWidgets({ target: { name: 'widgets', value: updatedWidgets } });
  };

  let isThermometerWidgetPresent = false;
  const { disabledDragWidgets, enabledDragWidgets } = widgets?.reduce(
    (acc, widget) => {
      if (widget.type === 'THERMOMETER') {
        acc.disabledDragWidgets.push(widget);
        isThermometerWidgetPresent = true;
      } else {
        acc.enabledDragWidgets.push(isThermometerWidgetPresent ? { ...widget, position: widget.position - 1 } : widget);
      }
      return acc;
    },
    { disabledDragWidgets: [], enabledDragWidgets: [] }
  );

  const disableAdd = disabled || updatedSelectableWidgets.length === 0 || enabledDragWidgets.filter(w => !w.type && !w._destroy).length > 0;

  const onDragEnd = result => {
    if (!result.destination) return;
    const newOrder = reorderIndexArray(enabledDragWidgets, result.source.index, result.destination.index);
    onChangeWidgets({
      target: {
        name: 'widgets',
        value: isThermometerWidgetPresent ? [...disabledDragWidgets, ...newOrder] : newOrder,
      },
    });
  };

  return (
    <>
      {isDraggable ? (
        <>
          {disabledDragWidgets.map(widget => {
            if (widget._destroy) return true;
            return (
              <WidgetContainer
                key={widget.id}
                widget={widget}
                widgets={widgets}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                isPartOfUpsell={isPartOfUpsell}
                selectableWidgets={updatedSelectableWidgets}
                updateWidget={updateWidget}
                onChangeWidgetType={onChangeWidgetType}
                removeWidget={removeWidget}
                donationAmounts={donationAmounts}
                upsellType={upsellType}
                disabled={disabled}
                editedUpsell={editedUpsell}
                isDraggable={false}
                page={page}
                upsellAmounts={upsellAmounts}
              />
            );
          })}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'droppableId'}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {enabledDragWidgets.map((widget, index) => {
                    if (widget._destroy) return null;

                    return (
                      <WidgetsContainerDraggable
                        key={widget.id}
                        widget={widget}
                        index={index}
                        updateWidget={updateWidget}
                        onChangeWidgetType={onChangeWidgetType}
                        removeWidget={removeWidget}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        isPartOfUpsell={isPartOfUpsell}
                        selectableWidgets={updatedSelectableWidgets}
                        donationAmounts={donationAmounts}
                        upsellType={upsellType}
                        disabled={disabled}
                        editedUpsell={editedUpsell}
                        isDraggable={isDraggable}
                        page={page}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <>
          {widgets.map(widget => {
            if (widget._destroy) return null;

            return (
              <WidgetContainer
                key={widget.id}
                widget={widget}
                widgets={widgets}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                isPartOfUpsell={isPartOfUpsell}
                selectableWidgets={updatedSelectableWidgets}
                updateWidget={updateWidget}
                onChangeWidgetType={onChangeWidgetType}
                removeWidget={removeWidget}
                donationAmounts={donationAmounts}
                upsellType={upsellType}
                disabled={disabled}
                editedUpsell={editedUpsell}
                isDraggable={isDraggable}
                page={page}
                upsellAmounts={upsellAmounts}
              />
            );
          })}
        </>
      )}
      <div className={classNames('mb-2', { 'mt-4': widgets.length > 0 })}>
        <AddListItemButton
          addItem={addNewWidget}
          addText="Add Widget"
          addSubText={addWidgetText}
          disabled={disableAdd}
        />
      </div>
    </>
  );
};

WidgetsContainer.propTypes = {
  selectableWidgets: PropTypes.array.isRequired,
  widgets: PropTypes.array.isRequired,
  onChangeWidgets: PropTypes.func.isRequired,
  isPetitionPage: PropTypes.bool,
  isPartOfUpsell: PropTypes.bool,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  donationAmounts: PropTypes.array.isRequired,
  upsellType: PropTypes.string,
  isDraggable: PropTypes.bool.isRequired,
  page: PropTypes.object,
  upsellAmounts: PropTypes.array,
};

export default WidgetsContainer;
