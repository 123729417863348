import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CollapsibleCard,
  FormContainer,
  FormColumn,
  ConfirmModal,
  FormCard,
  Icon,
  ToolTip,
  useAmplitude,
} from 'modules/common';
import WidgetSelector from './widget-selector.component';
import { widgetComponentSelector, widgetPreviewComponentSelector } from './tools';

const WidgetContainer = ({
  widget,
  onChangeWidgetType,
  selectableWidgets,
  primaryColor,
  secondaryColor,
  widgets,
  donationAmounts,
  isPartOfUpsell,
  disabled = false,
  upsellType,
  upsellPreviewDetails,
  editedUpsell,
  isDraggable,
  disableDragToggle,
  updateWidget,
  removeWidget,
  page,
  upsellAmounts,
}) => {
  const [cardCurrentlyOpen, setCardCurrentlyOpen] = useState(widget._isCardOpen);
  const [showRemovalModal, setShowRemovalModal] = useState(false);
  const [logEvent] = useAmplitude();

  const _updateWidget = ({ target: { name, value } }) => {
    updateWidget(widget.id, name, value);
  };

  /* When the state changes it triggers a re-render which corrects a bug where
  the marquee wouldn't restart after expanding the collapsed card. */
  const onToggleOpen = open => {
    setCardCurrentlyOpen(open);
    _updateWidget({ target: { name: '_isCardOpen', value: open } });
  };

  const toggleRemoveModal = () => setShowRemovalModal(!showRemovalModal);

  const onConfirmRemove = () => {
    logEvent('remove widget');
    removeWidget(widget.id);
    setShowRemovalModal(false);
  };

  const WidgetComponent = widget.type && widgetComponentSelector(widget.type);
  const WidgetPreviewComponent = widget.type && widgetPreviewComponentSelector(widget.type);

  // if widget is from api and we dont support it, show nothing
  if (!widget._isNew && !WidgetComponent) return null;

  const cardTitle = isDraggable ? (
    <div className="d-flex align-items-center">
      <Icon icon="bars" color="gray" size="lg" />
      <span className={'pl-4'}>{widget._name}</span>
    </div>
  ) : widget.type === 'THERMOMETER' ? (
    <div className="d-flex align-items-center">
      <span className="mr-1">{widget._name}</span>
      <ToolTip
        tooltip="Thermometer widget must be in the first position for your page."
        placement="bottom"
        className="mr-2 hidden md:block"
        classNameOverlay="text-wrap"
      >
        <Icon icon="info-circle" color="gray" size="lg" />
      </ToolTip>
    </div>
  ) : (
    widget._name
  );

  return (
    <>
      <Card className="custom-cards-container mb-4">
        {/* disable collapse on new items so we can always show any errors */}
        <CollapsibleCard
          open={cardCurrentlyOpen}
          title={cardTitle}
          forceRender={false}
          disableCollapse={widget._isNew}
          onToggleOpen={onToggleOpen}
          removeCollapsibleCard={disabled ? null : toggleRemoveModal}
          disableDragToggle={disableDragToggle}
        >
          <FormContainer className="pb-3 justify-content-center">
            <FormColumn xs={12} md={isPartOfUpsell ? 12 : 7} lg={isPartOfUpsell ? 12 : 7} xl={isPartOfUpsell ? 12 : 8}>
              <WidgetSelector
                onChangeWidgetType={onChangeWidgetType}
                widget={widget}
                selectableWidgets={selectableWidgets}
                disabled={disabled}
              >
                {WidgetComponent ? (
                  <WidgetComponent
                    widget={widget}
                    updateWidget={_updateWidget}
                    donationAmounts={donationAmounts}
                    disabled={disabled}
                    disableDragToggle={disableDragToggle}
                    page={page}
                    upsellAmounts={upsellAmounts}
                    upsellType={upsellType}
                    upsell={editedUpsell}
                  />
                ) : null}
              </WidgetSelector>
            </FormColumn>

            <FormColumn xs={12} md={isPartOfUpsell ? 12 : 5} lg={isPartOfUpsell ? 12 : 5} xl={isPartOfUpsell ? 12 : 4}>
              <FormCard
                className="preview-card"
                title="Preview"
                subtitle="Preview how this widget will look on your page."
              >
                {WidgetPreviewComponent ? (
                  <WidgetPreviewComponent
                    widget={widget}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    widgets={widgets}
                    donationAmounts={donationAmounts}
                    upsellType={upsellType}
                    upsellPreviewDetails={upsellPreviewDetails}
                    editedUpsell={editedUpsell}
                  />
                ) : null}
              </FormCard>
            </FormColumn>
          </FormContainer>
        </CollapsibleCard>
      </Card>

      <ConfirmModal
        show={showRemovalModal}
        title="Remove Widget"
        buttonText="Remove"
        handleClose={toggleRemoveModal}
        buttonColor="cancel"
        onSubmit={onConfirmRemove}
      >
        Are you sure you want to remove this widget from your page?
      </ConfirmModal>
    </>
  );
};

WidgetContainer.propTypes = {
  widget: PropTypes.object.isRequired,
  selectableWidgets: PropTypes.array.isRequired,
  onChangeWidgetType: PropTypes.func.isRequired,
  updateWidget: PropTypes.func.isRequired,
  removeWidget: PropTypes.func.isRequired,
  isPartOfUpsell: PropTypes.bool,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  widgets: PropTypes.array,
  donationAmounts: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  upsellType: PropTypes.string,
  upsellPreviewDetails: PropTypes.object,
  isDraggable: PropTypes.bool,
  disableDragToggle: PropTypes.func,
  page: PropTypes.object,
  upsellAmounts: PropTypes.array,
};

export default WidgetContainer;
