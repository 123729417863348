import React from 'react';
import PropTypes from 'prop-types';

import { FormCardSpaced } from 'modules/common';
import { BillPayUpsell } from 'modules/common/bill-pay-activity';

import {
  UpsellName,
  UpsellAmountMultiplier,
  ConnectedUpsellCommittee,
  UpsellVideoEmbed,
  ConnectedCampaign,
  EnhancedUpsell,
  Geographical,
  RecurringInterval,
  UpsellAction,
  Widgets,
} from './sections';
import { WIDGETS_MAP } from 'modules/common/widgets/tools';
import DonorCoverFees from './sections/donor-cover-fees.component';

function SlateUpsell(props) {
  const onEditUpsellPage = ({ target: { name, value } }) => {
    // if name is card then we want to replace the whole upsell_pages object (reused multiplier component from panel upsell cards)
    props.onUpdateEditedUpsell({ target: { name: name === 'card' ? 'upsell_pages' : name, value } });
  };

  return (
    <>
      <FormCardSpaced>
        <UpsellName {...props} />
        <UpsellAction {...props} />
      </FormCardSpaced>

      <UpsellAmountMultiplier onUpdateEditedUpsell={onEditUpsellPage} editedUpsell={props.editedUpsell} />
      <DonorCoverFees {...props} />

      <RecurringInterval onUpdateEditedUpsell={onEditUpsellPage} editedUpsell={props.editedUpsell} />

      <ConnectedUpsellCommittee
        onChange={props.onUpdateEditedUpsell}
        selectedCandidates={props.editedUpsell.candidates}
        disabled={props.editedUpsell._isDisabled}
        className="mb-3"
        splitEvenly={props.editedUpsell.split_evenly}
        showConduitingAndDataSharingToggle={!props.editedUpsell._isPreset}
      />

      <Geographical {...props} />

      <UpsellVideoEmbed {...props} />
      <EnhancedUpsell {...props} />

      <ConnectedCampaign {...props} />

      <Widgets {...props} widgetOptions={SlateUpsell.widgets} />

      {props.editedUpsell?._conduitsHaveVendors && (
        <BillPayUpsell
          revv_uid={props.editedUpsell?.revv_uid}
          upsellType="slate"
          isPublic={props.editedUpsell?.public_upsell}
          upsellCreator={props.editedUpsell?.upsell_creator_revv_uid}
          isNew={props.editedUpsell?._isNew}
        />
      )}
    </>
  );
}

SlateUpsell.title = 'Donation';
SlateUpsell.description = 'Ask donors to donate to your committee and/or other committees.';
SlateUpsell.icon = 'upsell-conduit';
SlateUpsell.type = 'CONDUIT';
SlateUpsell.widgets = [
  WIDGETS_MAP.MULTIPLIER.value,
  WIDGETS_MAP.EXITINTENTPOPUP.value,
  WIDGETS_MAP.AMOUNTBUTTONANIMATION.value,
  WIDGETS_MAP.ACCEPTBUTTONANIMATION.value,
  WIDGETS_MAP.SHOTCLOCK.value,
  WIDGETS_MAP.COUNTDOWN.value,
  WIDGETS_MAP.COUNTDOWNTOMIDNIGHT.value,
  WIDGETS_MAP.DONATIONSTREAM.value,
  WIDGETS_MAP.SOCIALLINKS.value,
  WIDGETS_MAP.POLLRESULTS.value,
  WIDGETS_MAP.CONFETTI.value,
];

SlateUpsell.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  onUpdateEditedUpsell: PropTypes.func.isRequired,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default SlateUpsell;
